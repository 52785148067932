/* Website Home */
.main-slider__title{
    font-size: calc(40px + (85 - 40) * ((100vw - 320px) / (1920 - 320))) !important;
    text-transform: uppercase !important;
    line-height: 50px !important
}
.main-slider__sub-title:before{
    bottom: -30px !important;
}
.main-menu .main-menu__list>li>ul{
    top: 85% !important;
    padding: 20px 5px !important;
}
.main-slider__btn-box{
    margin-top: 120px;
}
.main-menu .main-menu__list>li>a{
    text-transform: uppercase !important;
    font-size: 14px !important;
    color: #FFF !important;
    font-weight: bold !important;
}

.stricky-header .main-menu__list>li>a{
    color: #000 !important;
}

.main-menu .main-menu__list>li>ul>li>a{
    text-transform: uppercase;
}

.main-header{
    position: absolute !important;
    top: 0 !important;
}

.main-header__wrapper{
    background-color: transparent !important;
    padding: 0 200px !important; 
}
.contact-one__right{
    padding: 80px !important;
}
.thm-btn{
    border: none !important;
}
@media only screen and (max-width: 767px) {
    .main-header__wrapper{
        padding: 0 !important; 
    }
    .thm-btn i{
        margin-left: 0 !important;
    }
    .contact-one__right{
        padding: 10px !important;
    }
}


.main-slider .container{
    padding-bottom: 365px !important;
    padding-top: 300px !important;
}
.thm-btn{
    text-transform: uppercase;
}

.main-menu__btn--ovr{
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        margin-left: 5px !important;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        line-height: 20px;
        font-size: 11px;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
      }
}
.about-two{
    margin-top: 142px !important;
}

.contact-one{
    margin: 90px !important;
}

.main-menu__left{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.services-two__bg{
    opacity: 1 !important;
    top: -145px !important;
    height: 100% !important;
}

.services-two{
    padding: 120px 0 37px !important; 
}

.section-title__title--ovr{
    color: #FFF !important;
    text-shadow: 0px 1px 2px rgba(0,0,0,.5) !important;
    font-family: 'DM Sans', sans-serif !important;
}
.section-title__tagline--ovr{
    color: #FFF !important;
}
.about-Two__right--ovr{
    padding-top: 100px !important;
}

.services-two__content{
    padding: 120px 39px 22px !important;
}
.services-two__title-box{
    bottom: 110px !important;
}
.video-one{
    padding: 197px 0 116px !important;
}

.flx-cnt-hw{
    display: flex;
    align-items: center;
    justify-items: center;
}



#msg__container{
    display: none;
}

.show-elflex{
    margin-top: 15px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 10px;
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    border-radius: 10px;
    font-weight: bold;
}

.spacing{
    margin: 150px;
}

#logoscroll{
    margin-right: 5rem;
}
.health-food-two__inner{
    padding: 0 !important;
}

.ovr--health-food-two__shape-2{
    right: 0;
    z-index: 11111;
    bottom: 10px !important;
    left: auto !important;
}

.ovr--text-black{
    color: #000 !important;
}


.ovr--organic-only__points-list{
    position: relative;
    display: inline-block;
}

.ovr--title{
    // font-family: 'Alfa Slab One' !important;
    // color: #ff9935 !important;
    // text-shadow: 1px 1px 1px rgba(0,0,0,1);
    font-family: 'Dela Gothic One' !important;
}

.ovr--healthy-food-two__bg-color{
    top: 0 !important;
}
.ovr--healthy-food-two__bg-color_2{
    background-color: transparent !important;
}

.ovr--project-two__content{
    left: 0 !important;
    right: inherit;
    
}